var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-apex-charts',{attrs:{"width":_vm.IS_MOBILE() ? _vm.activities.length > 10 ? 12 * _vm.activities.length : '100%' : '100%',"height":"400","options":{
      ..._vm.chartOptions,
      xaxis: {
        categories: _vm.dates,
        tickAmount: _vm.activities.length > 20 ? 10 : 0,
        labels: {
          rotate: 315,
        }
      },
      yaxis: {
        labels: {
          formatter(val) {
            return _vm.CONVERT_HM(val)
          },
        },
        title: {
          text: _vm.$t('Time'),
        },
      },
      dataLabels: {
        enabled: false,
        formatter(val) {
          return _vm.CONVERT_HM(val)
        }
      },
    },"series":[{
      name: _vm.$t('reports.total-hours'),
      data: _vm.seconds,
    }]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }