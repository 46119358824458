<template>
  <div id="hours-tracked">
    <Filters
      v-model="form"
      :date-type="getDateType"
      :multiple-user-select="true"
      :user-placeholder="$t('All users')"
      @timezone-changed="updateTimezone"
    />
    <b-tabs
      id="tour-hours-tracked"
      v-model="activeTab"
      pills
    >
      <b-tab
        :title="$t('Day')"
        active
        lazy
      >
        <result
          :show-chart="false"
          :start-date="todayDate.start_date"
          :end-date="todayDate.end_date"
          :user-id="form.userId"
          :timezone="form.time_zone"
        />
      </b-tab>

      <b-tab
        :title="$t('Date Range')"
        lazy
        @click="setStartDate"
      >
        <result
          :start-date="rangeDate.start_date"
          :end-date="rangeDate.end_date"
          :user-id="form.userId"
          :timezone="form.time_zone"
        />
      </b-tab>
      <b-tab
        :title="$t('Summary')"
        lazy
      >
        <Summary
          :start-date="monthRangeDate.start_date"
          :end-date="monthRangeDate.end_date"
          :user-id="form.userId"
          :timezone="form.time_zone"
        />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>

import { getUserData } from '@/auth/utils'
import Filters from '@/views/common/components/Filter.vue'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Result from './Result.vue'
import Summary from './Summary.vue'

const moment = require('moment')

export default {
  components: {
    BTab,
    BTabs,
    Result,
    Filters,
    Summary,
  },
  data() {
    return {
      defaultUser: getUserData().uuid,
      form: {
        date: this.TODAY({ localTime: false }),
        start_date: this.TODAY({ localTime: false }),
        end_date: this.TODAY({ localTime: false }),
        month_start: moment().format('YYYY-MM'),
        month_end: moment().format('YYYY-MM'),
        userId: this.defaultUser,
        time_zone: localStorage.timezone ?? 'Asia/Kathmandu',
      },
      activeTab: 0,
    }
  },
  computed: {
    todayDate() {
      return {
        start_date: `${this.form.date} 00:00:00`,
        end_date: this.ADD_DAYS(this.form.date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    rangeDate() {
      return {
        start_date: `${this.form.start_date} 00:00:00`,
        end_date: this.ADD_DAYS(this.form.end_date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    monthRangeDate() {
      const monthStart = `${this.form.month_start}-01`
      const monthEnd = moment(`${this.form.month_end}-01`).endOf('month').format('YYYY-MM-DD')
      return {
        start_date: `${monthStart} 00:00:00`,
        end_date: this.ADD_DAYS(monthEnd, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    getDateType() {
      if (this.activeTab === 0) {
        return 'date'
      } if (this.activeTab === 2) {
        return 'month'
      }
      return 'range'
    },
  },
  watch: {
    'form.userId': {
      handler(val) {
        if (val && val.length) {
          this.setSelectedUser(val[0])
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    updateTimezone() {
      if (this.isAfterTODAY(this.form.date)) this.form.date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.start_date)) this.form.start_date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.end_date)) this.form.end_date = this.TODAY({ localTime: false })
    },
    setStartDate() {
      this.form.start_date = moment(this.form.start_date).startOf('month').format('YYYY-MM-DD')
    },
  },
}
</script>
